import "./App.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_URL } from "./config";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!email.match(isValidEmail)) {
      setError("Email is invalid");
    } else {
      var apd = {
        email: email,
        password: password,
      };
      let url = API_URL + "/login";
      axios
        .post(url, apd)
        .then((data) => {
          // console.log(data.data);
          props.setUserData(data.data);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("username", data.data.username);
          localStorage.setItem("email", data.data.email);
          localStorage.setItem("company_name", data.data.company_name);
          localStorage.setItem("about", data.data.about);
          navigate("/dashboard");
        })
        .catch((err) => {
          // document.write("Error occured");
          // alert("Invalid credentials");
          setError("Invalid credentials. Please try again.");
        });
    }
  };
  return (
    <div>
      <section className>
        <div className="w-100">
          <div className="row mx-0">
            <div className="col-12 col-md-4 min-vh-100 bg_primary d-none d-md-block" />
            <div className="col-12 col-md-8 min-vh-100 py-4 py-md-5 px-md-5">
              <div className="float-right">
                <p>
                  Don't have an account yet?
                  <a
                    href="./signup"
                    className="btn btn_light border border-dark px-3 px-md-4 ml-3"
                  >
                    Sign Up
                  </a>
                </p>
              </div>
              <div className="row mx-0 mt-5">
                <form
                  onSubmit={handleSubmit}
                  className="col-12 col-md-8 offset-md-2"
                >
                  <h2 className="font-weight-bold my-5">Login</h2>
                  <div className="form-group">
                    <small className="text-secondary font-weight-bold">
                      EMAIL
                    </small>
                    <input
                      type="text"
                      className="form-control bg-light rounded-lg"
                      id="login_username"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <small className="text-secondary font-weight-bold">
                      PASSWORD
                    </small>
                    <input
                      type="password"
                      className="form-control bg-light rounded-lg"
                      id="login_password"
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                  </div>
                  <Link
                    to="/forgot-password"
                    style={{ textDecoration: "underline" }}
                  >
                    Forgot password?
                  </Link>
                  <div className="form-group text-center mt-5">
                    <p style={{ color: "red", fontWeight: "bolder" }}>
                      {error}
                    </p>
                    <button className="btn btn-lg primary_btn mb-4 px-4">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
