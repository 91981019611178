import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import { API_URL } from "../config";

function Profile(props) {
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!email.match(isValidEmail)) {
      // alert("Email is invalid");
      setError("Email is invalid");
    } else {
      var apd = {
        email: email,
        old_password: oldPassword,
        new_password: newPassword,
      };
      let url = API_URL + "/resetPassword";
      axios
        .post(url, apd)
        .then((data) => {
          // console.log(data.data);
          // navigate("/dashboard");
          // alert("Your password has been updated!");
          setMsg("Your password has been updated!");
        })
        .catch((err) => {
          // document.write("Error occured");
          // alert("Invalid credentials");
          setError("Invalid credentials");
        });
    }
  };
  // console.log(props.jobs);
  // console.log(localStorage.getItem("username"));
  // console.log(props.userData);
  return (
    <div>
      <br />
      <p>
        Company Name:&nbsp;
        {/* {
          props.jobs.find(
            (item) => item.username === localStorage.getItem("username")
          )?.company_name
        } */}
        {localStorage.getItem("company_name")}
      </p>
      <br />
      <p>
        Email:&nbsp;
        {/* {
          props.jobs.find(
            (item) => item.username === localStorage.getItem("username")
          )?.email
        } */}
        {localStorage.getItem("email")}
      </p>
      <br />
      <p>
        Username:&nbsp;
        {/* {
          props.jobs.find(
            (item) => item.username === localStorage.getItem("username")
          )?.username
        } */}
        {localStorage.getItem("username")}
      </p>
      <br />
      <p>
        About:&nbsp;
        {/* {
          props.jobs.find(
            (item) => item.username === localStorage.getItem("username")
          )?.about
        } */}
        {localStorage.getItem("about")}
      </p>
      <form onSubmit={handleSubmit} className="col-12 col-md-8 offset-md-2">
        <h6 className="font-weight-bold my-3">Set new Password</h6>
        <div className="form-group">
          <small className="text-secondary font-weight-bold">EMAIL</small>
          <input
            type="text"
            className="form-control bg-light rounded-lg"
            id="login_username"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <small className="text-secondary font-weight-bold">PASSWORD</small>
          <input
            type="password"
            className="form-control bg-light rounded-lg"
            id="login_password"
            value={oldPassword}
            onChange={(event) => {
              setOldPassword(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <small className="text-secondary font-weight-bold">
            NEW PASSWORD
          </small>
          <input
            type="password"
            className="form-control bg-light rounded-lg"
            id="login_password"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <small className="text-secondary font-weight-bold">
            CONFIRM NEW PASSWORD
          </small>
          <input
            type="password"
            className="form-control bg-light rounded-lg"
            id="login_password"
            value={confirmNewPassword}
            onChange={(event) => {
              setConfirmNewPassword(event.target.value);
            }}
          />
        </div>
        <div className="form-group text-center mt-5">
          <p style={{ color: "red", fontWeight: "bolder" }}>{error}</p>
          <p style={{ color: "green", fontWeight: "bolder" }}>{msg}</p>
          <button className="btn btn-lg primary_btn mb-4 px-4">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default Profile;
