import "./App.css";
import Job from "./job";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { CREATE_JOB_EMAIL } from "./config";

function JobPoster(props) {
  const [token, setToken] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState("a");
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (modal) {
      document.body.classList.add("active-modal");
    } else if (!modal) {
      document.body.classList.remove("active-modal");
    }
  }, [modal]);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    // console.log(e.target.value);
  };
  if (searchInput.length > 0) {
    props.data.filter((item) => {
      // console.log(item);
    });
  }

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  return (
    <div>
      <div>
        {/* {console.log(props.data)} */}
        {/* <section className="">
          <form
            className=" py-4 px-md-5 my-20"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div className="form-group" id="email_grp">
              <input
                type="text"
                className="form-control bg-light rounded-lg"
                id="email"
                placeholder="Search"
                onChange={handleChange}
              />
            </div>
          </form>
        </section> */}
        <section className="py-4">
          <div className="container mb-3 px-0">
            <div className="">
              <p style={{ textAlign: "right" }}>
                {/* {token ? (
                  <Link
                    to="/dashboard"
                    className="btn btn_light border border-dark px-3 px-md-4 ml-3"
                  >
                    Dashboard
                  </Link>
                ) : null}

                {token ? null : (
                  <Link
                    to="/login"
                    className="btn btn_light border border-dark px-3 px-md-4 ml-3"
                  >
                    Log In
                  </Link>
                )}
                {token ? null : (
                  <Link
                    to="/signup"
                    className="btn btn_light border border-dark px-3 px-md-4 ml-3"
                  >
                    Sign Up
                  </Link>
                )} */}
              </p>
            </div>
            <br />
            {/* <div className="row mx-0 mb-3">
              <div className="col-6">
                <button
                  className="btn px-0"
                  type="button"
                  onclick="openFilterPanel()"
                >
                  <i class="fa fa-filter d-md-none"></i>
                  <small className>Filter</small>
                  <i className="fa fa-caret-down ml-1 ml-md-2" />
                </button>
              </div>
              <div className="col-6 text-right">
                <button className="btn px-0" onclick="openActionsPanel()">
                  <i class="fa fa-bars d-md-none"></i>
                  <small className>Sort By</small>
                  <i className="fa fa-caret-down ml-1 ml-md-2" />
                </button>
              </div>
            </div> */}
            {modal && (
              <div className="modal" style={{ display: "flex" }}>
                <div
                  onClick={() => {
                    toggleModal();
                  }}
                  className="overlay"
                ></div>
                {toggle === "a" ? (
                  <div className="filter-content">
                    <h2>Menu</h2>
                    <hr />
                    <div>
                      <Link to="/jobs">Home</Link>
                      <hr />
                      <Link to="/how-it-works">How It Works</Link>
                    </div>
                    <button className="close-modal" onClick={toggleModal}>
                      CLOSE
                    </button>
                  </div>
                ) : (
                  <div className="modal-content">
                    <h2>Note</h2>
                    <div>
                      <p>
                        Only authorized users can post job. Do you still want to
                        continue?
                      </p>
                      <br />
                      <a
                        href={
                          "mailto:" +
                          CREATE_JOB_EMAIL +
                          "?subject=Application for the job"
                        }
                        className="btn primary_btn px-1 py-1"
                      >
                        Continue
                      </a>
                    </div>
                    <button className="close-modal" onClick={toggleModal}>
                      CLOSE
                    </button>
                  </div>
                )}
              </div>
            )}
            {/* {modal && (
              <div className="modal" style={{ display: "flex" }}>
                <div onClick={toggleModal1} className="overlay"></div>
                <div className="modal-content">
                  <h2>Menu</h2>
                  <div>
                    <Link to="/jobs">Home</Link>
                    <br />
                    <Link to="/how-it-works">How It Works</Link>
                  </div>
                  <button className="close-modal" onClick={toggleModal1}>
                    CLOSE
                  </button>
                </div>
              </div>
            )} */}
            <section className="py-4 w-100">
              <div className="container px-0">
                <div className="row mx-0">
                  <div className="col-12">
                    <p className="font-weight-bold text_blue">
                      <p className="font-weight-bold text_blue">
                        {
                          props.data.find(
                            (item) => item.username === props.username
                          )?.company_name
                        }
                        {/* <span className="h6 green_tag">{props.data.email}</span> */}
                      </p>
                      {/* <span className="h6 green_tag">{props.data.email}</span> */}
                    </p>
                    {/* <p>
                  <a href="https://example.com" className="text-primary">
                  <u>https://market.background/johndoe</u>
                  </a>
                </p> */}
                    {/* <p className="clamp clamp2" id="background_para"> */}
                    {/* <h4>Job Description:</h4>
                    <p>
                      <div
                        className="dangerous"
                        dangerouslySetInnerHTML={{ __html: props.data[0].jd }}
                      ></div>
                    </p> */}
                    <h4>About:</h4>
                    <p>
                      {
                        props.data.find(
                          (item) => item.username === props.username
                        )?.about
                      }
                    </p>
                  </div>

                  <div className="container mb-3 px-0">
                    <div class="row mx-0 mb-3">
                      <div class="col-5">
                        <button
                          class="btn px-0"
                          type="button"
                          onClick={() => {
                            toggleModal();
                            setToggle("a");
                          }}
                        >
                          {/* <i class="fa fa-filter d-md-none"></i> */}
                          <small class="">Menu</small>
                          <i class="fa fa-caret-down ml-1 ml-md-2"></i>
                        </button>
                      </div>
                      <div class="col-2 text-center">
                        <button
                          onClick={() => {
                            toggleModal();
                            setToggle("b");
                          }}
                          class="btn px-0"
                        >
                          <i class="fa fa-plus-circle fa-2x"></i>
                        </button>
                      </div>
                      {/* <div class="col-5 text-right">
                        <button class="btn px-0" onclick="openActionsPanel()">
                          <i class="fa fa-bars d-md-none"></i>
                          <small class="">Actions</small>
                          <i class="fa fa-caret-down ml-1 ml-md-2"></i>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <form
                    className="col-12 px-md-5"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    // onSubmit={handleSubmit}
                  ></form>
                </div>
              </div>
            </section>
            {/* {console.log(props)} */}
            {props.filter === "false" ? (
              <div className="row mx-0">
                {props.data.map((item) => {
                  // console.log(item.company_name);
                  if (item.company_name.toLowerCase().includes(searchInput))
                    return (
                      <Job
                        id={item.id}
                        username={item.username}
                        company_name={item.company_name}
                        loggedIn="false"
                      />
                    );
                })}
                {/* <Job username={props.data[0].username} /> */}
              </div>
            ) : (
              <div className="row mx-0">
                {props.data.map((item) => {
                  // console.log(item.username);
                  if (item.username === props.username)
                    // if (
                    //   item.jd.includes(searchInput) ||
                    //   item.username.includes(searchInput)
                    // )
                    return (
                      <Job
                        id={item.id}
                        username={item.username}
                        company_name={item.company_name}
                        loggedIn="false"
                      />
                    );
                })}
                {/* <Job username={props.data[0].username} /> */}
              </div>
            )}
          </div>
        </section>
        {/* <div
          id="bottom_nav"
          className="fixed-bottom py-2 border border-secondary border-bottom-0"
        >
          <div className="row mx-0">
            <div className="col text-center px-0">
              <button className="btn btn-sm">
                <i className="fas fa-user-plus" />
                <br />
                Follow
              </button>
            </div>
            <div className="col text-center px-0">
              <button className="btn btn-sm">
                <i className="far fa-bookmark" />
                <br />
                Bookmark
              </button>
            </div>
            <div className="col text-center px-0">
              <button className="btn btn-sm">
                <i className="far fa-paper-plane" />
                <br />
                Connect
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default JobPoster;
