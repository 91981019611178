import "./App.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { API_URL } from "./config";

function SignUp() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [about, setAbout] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidNumber = /^\d{10}$/;
    const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!email.match(isValidEmail)) {
      setError("Email is invalid");
    } else if (!phone_number.match(isValidNumber)) {
      setError("Phone number is invalid");
    } else if (!password.match(isValidPassword)) {
      setError("Invalid Password");
    } else {
      // console.log(email, password, phone_number, about, username);
      var apd = {
        company_name: company_name,
        email: email,
        password: password,
        phone_number: phone_number,
        about: about,
        username: username,
      };

      let url = API_URL + "/register";
      axios
        .post(url, apd)
        .then((data) => {
          setMsg(
            "Successfully registered. Please approve through email and then login."
          );
          // alert(
          //   "Successfully registered. Please approve through email and then login."
          // );
          // navigate("/login");
          // console.log(data);
        })
        .catch((err) => {
          // console.log(err.response.data.error);
          setError(err.response.data.error);
          // document.write("Error occured. Please retry.");
        });
    }
  };

  return (
    <div className="App">
      <section className>
        <div className="w-100">
          <div className="row mx-0">
            <div className="col-12 col-md-4 min-vh-100 bg_primary d-none d-md-block" />
            <form
              className="col-12 col-md-8 min-vh-100 py-4 px-md-5"
              onSubmit={handleSubmit}
            >
              <div className="float-right">
                <p>
                  Already have an account?
                  <a
                    href="./login"
                    className="btn btn_light border border-dark px-3 px-md-4 ml-3"
                  >
                    Log In
                  </a>
                </p>
              </div>
              <div className="row mx-0 mt-5">
                <div className="col-12 col-md-8 offset-md-2">
                  <h2 className="font-weight-bold my-2">Sign Up</h2>
                  <div className="form-group" id="fullname_grp">
                    <small className="text-secondary font-weight-bold">
                      COMPANY NAME
                    </small>
                    <input
                      required
                      type="text"
                      className="form-control bg-light rounded-lg"
                      id="username"
                      value={company_name}
                      onChange={(event) => {
                        setCompany_name(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group" id="fullname_grp">
                    <small className="text-secondary font-weight-bold">
                      USERNAME
                    </small>
                    <input
                      required
                      type="text"
                      className="form-control bg-light rounded-lg"
                      id="username"
                      value={username}
                      onChange={(event) => {
                        setUsername(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group" id="email_grp">
                    <small className="text-secondary font-weight-bold">
                      EMAIL
                    </small>
                    <input
                      type="text"
                      className="form-control bg-light rounded-lg"
                      id="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group" id="achievement_grp">
                    <small className="text-secondary font-weight-bold">
                      Phone Number
                    </small>
                    <input
                      type="text"
                      className="form-control bg-light rounded-lg signup_achievements"
                      id="phone_number"
                      value={phone_number}
                      onChange={(event) => {
                        setPhone_number(event.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group" id="about_grp">
                    <small className="text-secondary font-weight-bold">
                      ABOUT
                    </small>
                    <textarea
                      className="form-control bg-light rounded-lg"
                      id="about"
                      value={about}
                      onChange={(event) => {
                        setAbout(event.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group" id="pin_grp">
                    <small className="text-secondary font-weight-bold">
                      PASSWORD
                    </small>
                    <input
                      type="password"
                      className="form-control bg-light rounded-lg"
                      id="password"
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group text-center mt-5">
                    <p style={{ color: "red", fontWeight: "bolder" }}>
                      {error}
                    </p>
                    <p style={{ color: "green", fontWeight: "bolder" }}>
                      {msg}
                    </p>
                    <button
                      type="submit"
                      className="btn btn-lg primary_btn px-4"
                      // onClick={handleSubmit()}
                    >
                      Sign Up
                    </button>
                    {/* <small className="d-block">
                      By clicking "Sign Up" you accept the{" "}
                      <a href className="text_primary">
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a href="" className="text_primary">
                        Privacy Policy
                      </a>
                    </small> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;
