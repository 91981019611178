import "./App.css";
import { useState } from "react";
import { useNavigate } from "react-router";
import MyJobs from "./Dashboard/myJobs";
import HowItWorks from "./Dashboard/howitworks";
import Profile from "./Dashboard/profile";
// import { useLocation } from "react-router";

function Dashboard(props) {
  // useEffect(() => {
  // });
  // console.log(props.userData);
  // const { state } = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("my-jobs");
  const toggleTab = (tab) => () => {
    // console.log(tab);
    setActiveTab(tab);
    document.getElementsByClassName("active")[0].classList.remove("active");
    // a.forEach((element) => {
    //   element.classList.remove("active");
    // });
    document.getElementById(activeTab).classList.add("active");
  };
  const handleClick = () => {
    localStorage.removeItem("token");
    navigate("/jobs");
  };
  return (
    <div className="container px-0 pt-4">
      {/* <p style={{ textAlign: "right" }}>
        <button
          onClick={handleClick}
          className="btn btn_light border border-dark px-3 px-md-4 ml-3"
        >
          Log Out
        </button>
      </p>
      <br />
      Your profile is :
      {props.userData.is_verified ? <p>Verified</p> : <p>Not Verified</p>}
      {props.userData.is_approved ? <p>Approved</p> : <p>Not Approved</p>}
      <br />
      Here are the instructions of how to post a job. */}
      {/* {console.log(props.userData)} */}
      <p style={{ textAlign: "right" }}>
        <button
          onClick={handleClick}
          className="btn btn_light border border-dark px-3 px-md-4 ml-3"
        >
          Log Out
        </button>
      </p>
      <div>
        <div className="container px-0">
          <div className="row mx-0">
            <div className="col-12">
              <ul className="nav nav-tabs" id="dashboard_tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    onClick={toggleTab("my-jobs")}
                    className="nav-link active"
                    id="my-jobs"
                    // data-toggle="tab"
                    // href="#public-posts"
                    // role="tab"
                  >
                    My Jobs
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={toggleTab("how-it-works")}
                    className="nav-link"
                    id="how-it-works"
                    // data-toggle="tab"
                    // href="#private-posts"
                    // role="tab"
                  >
                    How it works
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={toggleTab("profile")}
                    className="nav-link"
                    id="profile"
                    // data-toggle="tab"
                    // href="#bookmarked-posts"
                    // role="tab"
                  >
                    Profile
                  </button>
                </li>
              </ul>
              {/* <div className="tab-content" id="dashboard_tab_content">
                <div
                  className="tab-pane fade show active"
                  id="public-posts"
                  role="tabpanel"
                >
                  Public
                </div>
                <div
                  className="tab-pane fade"
                  id="private-posts"
                  role="tabpanel"
                >
                  Private
                </div>
                <div
                  className="tab-pane fade"
                  id="bookmarked-posts"
                  role="tabpanel"
                >
                  Bookmarked
                </div>
              </div> */}
              {activeTab === "my-jobs" ? (
                <MyJobs
                  setJobs={props.setJobs}
                  data={props.jobs}
                  userData={props.userData}
                  isLoading={props.isLoading}
                  setIsLoading={props.setIsLoading}
                />
              ) : null}
              {activeTab === "how-it-works" ? <HowItWorks /> : null}
              {activeTab === "profile" ? (
                <Profile userData={props.userData} jobs={props.jobs} />
              ) : null}
              {/* {console.log(props.userData)} */}
            </div>
          </div>
        </div>
        <section className="py-4">
          <div className="container mb-3 px-0">
            <div className="row mx-0 mb-3">
              {/* <div className="col-6">
                <button
                  className="btn px-0"
                  type="button"
                  onclick="openFilterPanel()"
                >
                  <small className>Filter</small>
                  <i className="fa fa-caret-down ml-1 ml-md-2" />
                </button>
              </div> */}
              {/* <div className="col-6 text-right">
                <button className="btn px-0" onclick="openActionsPanel()">
                  <small className>Sort By</small>
                  <i className="fa fa-caret-down ml-1 ml-md-2" />
                </button>
              </div> */}
            </div>
            {/* <div className="row mx-0">
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4">
                <div className="p-2 post_box">
                  <p className="h5">
                    Position building in
                    <b className="text_secondary">Cryptocurrency</b>
                    with
                    <a href="./profile.html">
                      <u className="font-weight-bold">John Doe</u>
                    </a>
                  </p>
                  <p className="text_primary">
                    <a
                      role="button"
                      tabIndex={0}
                      className="btn btn-sm text_primary p-0"
                      data-toggle="popover"
                      data-placement="bottom"
                      data-trigger="focus"
                      data-html="true"
                      title="Close ×"
                      data-content="Date<br>City, country<br>tag1, tag2, tag3"
                    >
                      <u>Show Tags</u>
                    </a>
                    <span className="float-right text-secondary">
                      <a href>
                        <small>
                          <u>Edit</u>
                        </small>
                      </a>
                      <button className="btn btn-sm px-0 ml-3">
                        <i className="fa fa-plus" />
                      </button>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4">
                <div className="p-2 post_box">
                  <p className="h5">
                    Position building in
                    <b className="text_secondary">Cryptocurrency</b>
                    with
                    <a href="./profile.html">
                      <u className="font-weight-bold">John Doe</u>
                    </a>
                  </p>
                  <p className="text_primary">
                    <a
                      role="button"
                      tabIndex={0}
                      className="btn btn-sm text_primary p-0"
                      data-toggle="popover"
                      data-placement="bottom"
                      data-trigger="focus"
                      data-html="true"
                      title="Close ×"
                      data-content="Date<br>City, country<br>tag1, tag2, tag3"
                    >
                      <u>Show Tags</u>
                    </a>
                    <span className="float-right text-secondary">
                      <a href>
                        <small>
                          <u>Edit</u>
                        </small>
                      </a>
                      <button className="btn btn-sm px-0 ml-3">
                        <i className="fa fa-plus" />
                      </button>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4">
                <div className="p-2 post_box">
                  <p className="h5">
                    Position building in
                    <b className="text_secondary">Cryptocurrency</b>
                    with
                    <a href="./profile.html">
                      <u className="font-weight-bold">John Doe</u>
                    </a>
                  </p>
                  <p className="text_primary">
                    <a
                      role="button"
                      tabIndex={0}
                      className="btn btn-sm text_primary p-0"
                      data-toggle="popover"
                      data-placement="bottom"
                      data-trigger="focus"
                      data-html="true"
                      title="Close ×"
                      data-content="Date<br>City, country<br>tag1, tag2, tag3"
                    >
                      <u>Show Tags</u>
                    </a>
                    <span className="float-right text-secondary">
                      <a href>
                        <small>
                          <u>Edit</u>
                        </small>
                      </a>
                      <button className="btn btn-sm px-0 ml-3">
                        <i className="fa fa-plus" />
                      </button>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4">
                <div className="p-2 post_box">
                  <p className="h5">
                    Position building in
                    <b className="text_secondary">Cryptocurrency</b>
                    with
                    <a href="./profile.html">
                      <u className="font-weight-bold">John Doe</u>
                    </a>
                  </p>
                  <p className="text_primary">
                    <a
                      role="button"
                      tabIndex={0}
                      className="btn btn-sm text_primary p-0"
                      data-toggle="popover"
                      data-placement="bottom"
                      data-trigger="focus"
                      data-html="true"
                      title="Close ×"
                      data-content="Date<br>City, country<br>tag1, tag2, tag3"
                    >
                      <u>Show Tags</u>
                    </a>
                    <span className="float-right text-secondary">
                      <a href>
                        <small>
                          <u>Edit</u>
                        </small>
                      </a>
                      <button className="btn btn-sm px-0 ml-3">
                        <i className="fa fa-plus" />
                      </button>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4">
                <div className="p-2 post_box">
                  <p className="h5">
                    Position building in
                    <b className="text_secondary">Cryptocurrency</b>
                    with
                    <a href="./profile.html">
                      <u className="font-weight-bold">John Doe</u>
                    </a>
                  </p>
                  <p className="text_primary">
                    <a
                      role="button"
                      tabIndex={0}
                      className="btn btn-sm text_primary p-0"
                      data-toggle="popover"
                      data-placement="bottom"
                      data-trigger="focus"
                      data-html="true"
                      title="Close ×"
                      data-content="Date<br>City, country<br>tag1, tag2, tag3"
                    >
                      <u>Show Tags</u>
                    </a>
                    <span className="float-right text-secondary">
                      <a href>
                        <small>
                          <u>Edit</u>
                        </small>
                      </a>
                      <button className="btn btn-sm px-0 ml-3">
                        <i className="fa fa-plus" />
                      </button>
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        {/* <div
          id="bottom_nav"
          className="fixed-bottom py-2 border border-secondary border-bottom-0"
        >
          <div className="row mx-0">
            <div className="col text-center px-0">
              <button className="btn btn-sm">
                <i className="fas fa-user-plus" />
                <br />
                Follow
              </button>
            </div>
            <div className="col text-center px-0">
              <button className="btn btn-sm">
                <i className="far fa-bookmark" />
                <br />
                Bookmark
              </button>
            </div>
            <div className="col text-center px-0">
              <button className="btn btn-sm">
                <i className="far fa-paper-plane" />
                <br />
                Connect
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
