import "./App.css";
import { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { API_URL } from "./config";

function SignUp(props) {
  const [userEmail, setUserEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [verified, setVerified] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!userEmail.match(isValidEmail)) {
      // setError("Email is invalid");
      console.log("Email invalid");
    } else {
      var apd = {
        poster_email: props.data.email,
        username: props.data.username,
        seeker_email: userEmail,
      };
      let url = API_URL + "/posterSignUp";
      axios
        .post(url, apd)
        .then((data) => {
          // console.log(data);
          setSuccess(true);
        })
        .catch((err) => {
          document.write("Error occured");
        });
    }
  };

  const onChange = () => {
    // console.log("recaptcha");
    setVerified(true);
  };
  return (
    <div>
      <div>
        <div id="filter_section" className="py-3">
          <div className="container d-none" id="filter_content">
            <div className="row mx-0">
              <div className="col-12">
                <button
                  className="btn btn-lg text_secondary float-right pt-0 pr-0"
                  onclick="closeFilterPanel()"
                >
                  <i className="fa fa-times" />
                </button>
                <h4 className="mb-4 font-weight-bold text_secondary">
                  Filter by
                </h4>
                <a
                  className="py-2 d-block expand_heading"
                  data-toggle="collapse"
                  href="#market_style"
                  role="button"
                >
                  <h5 className="mb-0">
                    Market &amp; Style{" "}
                    <span className="float-right fa fa-plus text_primary" />
                  </h5>
                </a>
                <div className="pl-2 mb-2 collapse fade" id="market_style">
                  <a
                    className="py-2 d-block expand_heading border-top"
                    data-toggle="collapse"
                    href="#market_div"
                    role="button"
                  >
                    <h5 className="mb-0">
                      Market{" "}
                      <span className="float-right fa fa-plus text_primary" />
                    </h5>
                  </a>
                  <div className="pl-2 mb-2 collapse fade" id="market_div">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Filter market"
                      list="markets"
                    />
                    <datalist id="markets">
                      <option value="Small" />
                      <option value="Medium" />
                      <option value="Large" />
                    </datalist>
                  </div>
                  <a
                    className="py-2 d-block expand_heading border-top"
                    data-toggle="collapse"
                    href="#style_div"
                    role="button"
                  >
                    <h5 className="mb-0">
                      Style{" "}
                      <span className="float-right fa fa-plus text_primary" />
                    </h5>
                  </a>
                  <div className="pl-2 mb-2 collapse fade" id="style_div">
                    <p>
                      <a href="https://example.com" className="text_secondary">
                        <u>Link 1</u>
                      </a>
                    </p>
                    <p>
                      <a href="https://example.com" className="text_secondary">
                        <u>Link 1</u>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <button className="btn primary_btn">Apply filters</button>
                </div>
              </div>
              <div className="col-12 mt-4">
                <p className="text-center mb-3">
                  <small>OR</small>
                </p>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Keywords"
                />
                <div className="text-center mt-3">
                  <button className="btn primary_btn">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="profile-main"
          style={{ display: "flex", marginBottom: "50px" }}
        >
          <section className="py-4 w-100">
            <div className="container px-0">
              <div className="row mx-0">
                <div className="col-12">
                  <Link to={`/${props.data.username}`}>
                    <p className="font-weight-bold text_blue">
                      {props.data.company_name}{" "}
                      {/* <span className="h6 green_tag">{props.data.email}</span> */}
                    </p>
                  </Link>
                  {/* <p>
                  <a href="https://example.com" className="text-primary">
                  <u>https://market.background/johndoe</u>
                  </a>
                </p> */}
                  {/* <p className="clamp clamp2" id="background_para"> */}
                  <h4>Job Description:</h4>
                  <p>
                    <div
                      className="dangerous"
                      dangerouslySetInnerHTML={{ __html: props.data.jd }}
                    ></div>
                  </p>
                  <h4>Job About:</h4>
                  <p>{props.data.about}</p>
                  <br />
                  <span style={{ display: "flex" }}>
                    See other jobs from&nbsp;
                    <Link to={`/${props.data.username}`}>
                      <p className="font-weight-bold text_blue">
                        {props.data.company_name}{" "}
                        {/* <span className="h6 green_tag">{props.data.email}</span> */}
                      </p>
                    </Link>
                  </span>
                </div>

                <form
                  className="col-12 py-4 px-md-5"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                  onSubmit={handleSubmit}
                >
                  <div className="form-group text-center mt-5">
                    <p style={{ color: "red", fontWeight: "bolder" }}></p>
                    <ReCAPTCHA
                      className="recaptcha"
                      sitekey="6LdWgjwlAAAAAB0oUPydtUIGFiM7XWwbf9NCkbg8"
                      onChange={onChange}
                    />
                    <br />
                    <a
                      // className="btn btn-lg primary_btn px-4 mb-2"
                      className={
                        !verified
                          ? "profile-btn-disabled btn btn-lg primary_btn"
                          : "profile-btn btn btn-lg primary_btn"
                      }
                      // style={{ pointerEvents: "none", opacity: "0.5", cursor: "pointer" }}
                      href={
                        "mailto:" +
                        props.data.email +
                        "?subject=Application for the job&body=" +
                        window.location.href
                      }
                      target="_blank"
                    >
                      Apply through email
                    </a>
                    &nbsp;&nbsp;
                    <a
                      // className="btn btn-lg primary_btn px-4 mb-2"
                      className={
                        !verified
                          ? "profile-btn-disabled btn btn-lg primary_btn"
                          : "profile-btn btn btn-lg primary_btn"
                      }
                      href={
                        "https://wa.me/+91" +
                        props.data.phone +
                        "?text=urlencodedtext"
                      }
                      target="_blank"
                    >
                      Apply through Whatsapp
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </section>
          {/* {console.log(window.innerWidth)} */}
          <div
            style={{ display: "flex", width: "", justifyContent: "center" }}
            // className={window.innerWidth > 1000 ? "" : ""}
          >
            {modal && (
              <div className="modal" style={{ display: "flex" }}>
                <div onClick={toggleModal} className="overlay"></div>
                <div className="modal-content">
                  <form
                    className=" py-4 px-md-5"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group" id="email_grp">
                      <small className="text-secondary font-weight-bold">
                        Sign up for email notifications from this recruiter
                      </small>
                      <input
                        type="text"
                        className="form-control bg-light rounded-lg"
                        id="email"
                        placeholder="example@gmail.com"
                        value={userEmail}
                        onChange={(event) => {
                          setUserEmail(event.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group text-center mt-1">
                      <p style={{ color: "red", fontWeight: "bolder" }}></p>
                      <button
                        type="submit"
                        className="btn btn-lg primary_btn px-4"
                      >
                        Sign Up
                      </button>
                      {success ? (
                        <p style={{ color: "green" }}>
                          You have successfully signed up for email
                          notifications from this recruiter
                        </p>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </form>
                  <button className="close-modal" onClick={toggleModal}>
                    CLOSE
                  </button>
                </div>
              </div>
            )}
            <div
              style={{
                minWidth: "300px",
                marginTop: "100px",
                marginRight: "30px",
              }}
              class="col-12 col-lg-3 px-lg-4 mb-3"
            >
              <div class="list-group">
                <a href={"mailto:"} target="_blank">
                  <button
                    style={{ padding: "0.75rem 0rem", paddingLeft: "0.75rem" }}
                    type="button"
                    class="list-group-item list-group-item-action post_details_side_btns"
                  >
                    <i class="fas fa-envelope mr-2"></i>
                    Email this
                  </button>
                </a>
                <a href="https://twitter.com" target="_blank">
                  <button
                    style={{ padding: "0.75rem 0rem", paddingLeft: "0.75rem" }}
                    type="button"
                    class="list-group-item list-group-item-action post_details_side_btns"
                  >
                    <i class="fab fa-twitter mr-2"></i>
                    Tweet this
                  </button>
                </a>
                <a
                  href={"whatsapp://send?text=Hey Check this out!"}
                  target="_blank"
                >
                  <button
                    style={{ padding: "0.75rem 0rem", paddingLeft: "0.75rem" }}
                    type="button"
                    class="list-group-item list-group-item-action post_details_side_btns"
                  >
                    <i class="fab fa-whatsapp mr-2"></i> WhatApp this
                  </button>
                </a>
                <a href="https://facebook.com" target="_blank">
                  <button
                    style={{ padding: "0.75rem 0rem", paddingLeft: "0.75rem" }}
                    type="button"
                    class="list-group-item list-group-item-action post_details_side_btns"
                  >
                    <i class="fab fa-facebook-f mr-2"></i>
                    Facebook this
                  </button>
                </a>
              </div>
            </div>

            <div id="bottom_nav" class="fixed-bottom py-2 border-bottom-0">
              <div class="row mx-0">
                <div class="col text-center px-0">
                  <Link to="/how-it-works" class="btn btn-sm">
                    <i class="fa fa-question text_blue"></i>
                    <br />
                    How it works
                  </Link>
                </div>
                <div class="col text-center px-0">
                  <Link to="/jobs" class="btn btn-sm">
                    <i class="fa fa-home text_blue"></i>
                    <br />
                    Home
                  </Link>
                </div>
                <div class="col text-center px-0">
                  <button onClick={toggleModal} class="btn btn-sm">
                    <i class="fas fa-user-plus text_blue"></i>
                    <br />
                    Follow
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
