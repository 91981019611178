import "./App.css";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_URL } from "./config";
import { useEffect } from "react";

function Job(props) {
  const [modal, setModal] = useState(false);
  

  const toggleModal = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  // console.log(props.loggedIn);
  const handleIt = (e) => {
    e.preventDefault();
    // console.log(props.id);
    var apd = {
      id: props.id,
    };
    let url = API_URL + "/deleteJobById";
    axios
      .post(url, apd)
      .then((data) => {
        // console.log(data);
        props.setData(props.id);
        toggleModal(e);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4">
      {modal && (
        <div className="modal" style={{ display: "flex" }}>
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <h2>Delete</h2>
            <p>Are you sure you want to delete this job posting?</p>
            <button className="btn btn-danger" onClick={(e) => handleIt(e)}>
              Delete
            </button>
            <button
              className="close-modal btn btn-secondary"
              onClick={toggleModal}
            >
              CLOSE
            </button>
          </div>
        </div>
      )}
      <a href={props.id}>
        <div className="p-2 post_box" style={{ minHeight: "120px" }}>
          <p className="h5">
            <b>
              <u>Build position</u>
            </b>{" "}
            in <span style={{ color: "green" }}>{props.company_name}</span> via{" "}
            <span style={{ color: "magenta" }}>positionbuilding.com</span>{" "}
          </p>
          <p className="text_primary">
            {/* {console.log(props)} */}
            {/* <a
            role="button"
            tabIndex={0}
            className="btn btn-sm text_primary p-0"
            data-toggle="popover"
            data-placement="bottom"
            data-trigger="focus"
            data-html="true"
            title="Close ×"
            data-content="Date<br>City, country<br>tag1, tag2, tag3"
          >
            <u>Show Tags</u>
          </a> */}
            {/* <span className="float-bottom text-secondary">
              By {props.username}
            </span> */}
            {props.loggedIn === "true" ? (
              <span style={{ zIndex: 10 }} class="float-right text-secondary">
                <button
                  onClick={(e) => {
                    toggleModal(e);
                  }}
                  //  onClick={(e) => handleIt(e)}
                >
                  <small>
                    <u>Delete</u>
                  </small>
                </button>
                <button class="btn btn-sm px-0 ml-3">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
            ) : null}
          </p>
        </div>
      </a>
    </div>
  );
}

export default Job;
