import { useEffect } from "react";
import call_icon from "./Assets/call-icon-light.png";

export default function Widget() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const apply = (class_name, func) => {
    Array.from(document.getElementsByClassName(class_name)).forEach(func);
  };

  const addListener = (class_name, func) => {
    apply(class_name, (element) => {
      element.addEventListener("click", func);
    });
  };

  const sleep = (time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time * 1000);
    });
  };

  function isValidEmail(email) {
    // Regular expression to match email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the regular expression
    return emailRegex.test(email);
  }

  async function fetchWithTimeout(url, data, timeout = 10000) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    });

    clearTimeout(id);
    return response;
  }

  let mail_done = false;
  let call_done = false;

  const main = () => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date();
    let day = date.getDay() + 2;
    const options = [];
    for (let count = 0; count < 5; count++) {
      options.push(days[day]);
      day = (day + 1) % 7;
    }
    apply("call-day", (element) => {
      options.forEach((option) => {
        const option_el = document.createElement("option");
        option_el.innerText = option;
        option_el.value = option;
        element.appendChild(option_el);
      });
    });

    addListener("widget-icon", (event) => {
      apply("widget-container", (el) => el.classList.toggle("none"));
    });

    addListener("call-icon", (event) => {
      apply("widget-call", (element) => {
        element.style.display = "block";
      });
      apply("widget-mail", (element) => {
        element.style.display = "none";
      });
    });

    addListener("mail-icon", (event) => {
      apply("widget-call", (element) => {
        element.style.display = "none";
      });
      apply("widget-mail", (element) => {
        element.style.display = "block";
      });
    });

    addListener("get-started", async (event) => {
      toggleLoader(event.target);
      let recaptchaResponse = "from_mobile";

      const p =
        event.target.parentElement.parentElement.parentElement.parentElement
          .children[1].children[0];

      if (!recaptchaResponse) {
        p.innerText = "Please submit reCAPTCHA first";
        apply("widget-footer", (footer) => footer.classList.add("error"));
        toggleLoader(event.target);
        return;
      }

      if (mail_done) {
        p.innerText = "Your request has already been submitted";
        apply("widget-footer", (footer) => footer.classList.add("error"));
        toggleLoader(event.target);
        return;
      }
      const temp =
        event.target.parentElement.parentElement.children[1].children;
      const email = temp[1].value.trim();
      const companyName = temp[2].value.trim();
      const inviteCode = temp[3].value.trim();

      if (!isValidEmail(email)) {
        p.innerText = "Please enter a valid email";
        apply("widget-footer", (footer) => footer.classList.add("error"));
        toggleLoader(event.target);
        return;
      }

      const data = {
        email,
        companyName,
        inviteCode,
        recaptchaResponse,
      };

      console.log(data);

      try {
        const response = await fetchWithTimeout(
          "https://widget.positionbuilding.com/api/mail",
          data
        );

        console.log(response.body);

        if (response.status !== 200) {
          throw new Error();
        }

        p.innerText =
          "Your request is submitted, click on your prefered mode of communication to proceed";

        apply("widget-footer", (footer) => {
          footer.classList.remove("none");
          footer.classList.remove("error");
        });

        mail_done = true;
        toggleLoader(event.target);
      } catch (e) {
        p.innerText = "Could not process your request";
        apply("widget-footer", (footer) => footer.classList.add("error"));

        toggleLoader(event.target);
      }
    });
  };

  addListener("call", async (event) => {
    toggleLoader(event.target);
    let recaptchaResponse = "from_mobile";

    const p =
      event.target.parentElement.parentElement.parentElement.parentElement
        .children[1].children[0];

    if (!recaptchaResponse) {
      p.innerText = "Please submit reCAPTCHA first";
      toggleLoader(event.target);
      return;
    }

    if (call_done) {
      p.innerText = "Your request has already been submitted";
      toggleLoader(event.target);
      return;
    }
    const temp = event.target.parentElement.parentElement.children[1].children;
    const day = temp[1].children[0].value.trim();
    const time = temp[1].children[2].value.trim();
    const number = temp[2].value.trim();

    if (number.length < 10) {
      p.innerText = "Please enter a valid phone number";
      toggleLoader(event.target);
      return;
    }
    const data = {
      day,
      time,
      number,
      recaptchaResponse,
    };

    try {
      const response = await fetchWithTimeout(
        "https://widget.positionbuilding.com/api/call",
        data
      );

      if (response.status !== 200) {
        throw new Error();
      }

      p.innerText = "Your call has been scheduled successfully";
      call_done = true;
      toggleLoader(event.target);
    } catch {
      p.innerText = "Your call could not be scheduled";
      toggleLoader(event.target);
    }
  });

  const toggleLoader = (button) => {
    button.classList.toggle("none");
    apply("widget-loader", (loader) => loader.classList.toggle("none"));
  };

  window.onload = () => {
    main();
  };

  return (
    <div class="widget-animation-container">
      <div class="widget-animation"></div>
      <div class="widget-icon">
        <img src={call_icon} alt="" />
      </div>
      <div class="widget-container none">
        <div class="widget">
          <div class="widget-call">
            <div class="widget-header">
              Would you like us to call you at a specified time?
            </div>
            <div class="form">
              <p>We will answer your questions</p>
              <div class="select-container">
                <select class="call-day">
                  <option value="Today">Today</option>
                  <option value="Today">Tomorrow</option>
                </select>
                <span>at</span>
                <select class="call-time">
                  <option value="10:00 am">10:00 am</option>
                  <option value="10:15 am">10:15 am</option>
                  <option value="10:30 am">10:30 am</option>
                  <option value="10:45 am">10:45 am</option>
                  <option value="11:00 am">11:00 am</option>
                  <option value="11:15 am">11:15 am</option>
                  <option value="11:30 am">11:30 am</option>
                  <option value="11:45 am">11:45 am</option>
                  <option value="12:00 pm">12:00 pm</option>
                  <option value="12:15 pm">12:15 pm</option>
                  <option value="12:30 pm">12:30 pm</option>
                  <option value="12:45 pm">12:45 pm</option>
                  <option value="01:00 pm">01:00 pm</option>
                  <option value="01:15 pm">01:15 pm</option>
                  <option value="01:30 pm">01:30 pm</option>
                  <option value="01:45 pm">01:45 pm</option>
                  <option value="02:00 pm">02:00 pm</option>
                  <option value="02:15 pm">02:15 pm</option>
                  <option value="02:30 pm">02:30 pm</option>
                  <option value="02:45 pm">02:45 pm</option>
                  <option value="03:00 pm">03:00 pm</option>
                  <option value="03:15 pm">03:15 pm</option>
                  <option value="03:30 pm">03:30 pm</option>
                  <option value="03:45 pm">03:45 pm</option>
                  <option value="04:00 pm">04:00 pm</option>
                  <option value="04:15 pm">04:15 pm</option>
                  <option value="04:30 pm">04:30 pm</option>
                  <option value="04:45 pm">04:45 pm</option>
                  <option value="05:00 pm">05:00 pm</option>
                  <option value="05:15 pm">05:15 pm</option>
                  <option value="05:30 pm">05:30 pm</option>
                  <option value="05:45 pm">05:45 pm</option>
                  <option value="06:00 pm">06:00 pm</option>
                  <option value="06:15 pm">06:15 pm</option>
                  <option value="06:30 pm">06:30 pm</option>
                  <option value="06:45 pm">06:45 pm</option>
                  <option value="07:00 pm">07:00 pm</option>
                  <option value="07:15 pm">07:15 pm</option>
                  <option value="07:30 pm">07:30 pm</option>
                  <option value="07:45 pm">07:45 pm</option>
                  <option value="08:00 pm">08:00 pm</option>
                  <option value="08:15 pm">08:15 pm</option>
                  <option value="08:30 pm">08:30 pm</option>
                  <option value="08:45 pm">08:45 pm</option>
                  <option value="09:00 pm">09:00 pm</option>
                  <option value="09:15 pm">09:15 pm</option>
                  <option value="09:30 pm">09:30 pm</option>
                  <option value="09:45 pm">09:45 pm</option>
                  <option value="10:00 pm">10:00 pm</option>
                  <option value="10:15 pm">10:15 pm</option>
                  <option value="10:30 pm">10:30 pm</option>
                </select>
              </div>
              <input type="text" value="+91" />
              <div
                class="g-recaptcha"
                data-sitekey="6LdtTpElAAAAAESKfkaQlJcRAdD7JinduV7oqguu"
              ></div>
            </div>
            <div class="button-container">
              <button class="call">Call me later</button>
              <div class="widget-loader none"></div>
            </div>
            <div class="disclaimer">
              <p>
                This call will be recorded for quality and training purposes.
                Free call back request!
              </p>
            </div>
          </div>
          <div class="widget-mail">
            <div class="widget-header">Position Building Support</div>
            <div class="form">
              <p>Talk to our Position Building executive</p>
              <input type="email" placeholder="E-Mail Address" />
              <input type="text" placeholder="Company Name" />
              <input type="text" placeholder="Invite Code" />
              {/* <textarea rows="5">Enter your message</textarea> */}
              <div
                class="g-recaptcha"
                data-sitekey="6LdtTpElAAAAAESKfkaQlJcRAdD7JinduV7oqguu"
              ></div>
            </div>
            <div class="button-container">
              <button class="get-started">Get started</button>
              <div class="widget-loader none"></div>
            </div>
          </div>
        </div>

        <div class="widget-footer none">
          <p></p>
          <div class="icons">
            <div class="icon call-icon">
              <img src="./Assets/call-icon-light.png" alt="" />
            </div>
            <a
              target="_blank"
              href="mailto:manjeetrahulmail@gmail.com?subject=Subject!&body=Some random body!"
              class="icon mail-icon"
            >
              <img src="./Assets/mail-icon-dark.png" alt="" />
            </a>
            <a
              target="_blank"
              href="https://wa.me/919108095640?text=I%20want%20to%20schedule%20a%20call%20to%20know%20more%20about%20position%20building%20services"
              className="icon whatsapp-icon"
            >
              <img src="./Assets/whatsapp-icon-light.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
