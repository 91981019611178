import "./App.css";
import SignUp from "./signup";
import Login from "./login";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { useEffect, useState } from "react";
import HowItWorks from "./howItWorks";
import Profile from "./profile";
import Home from "./home";
import axios from "axios";
import Dashboard from "./dashboard";
import Modal from "./modal";
import JobPoster from "./jobPoster";
import ForgotPassword from "./forgotPassword";
import LandingPage from "./landing";
import "./modal.css";
import { API_URL } from "./config";

function App() {
  const [dynamicRoutes, setDynamicRoutes] = useState([]);
  const [posterRoutes, setPosterRoutes] = useState([]);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    // console.log(process.env.REACT_APP_API_URL);
    // console.log(API_URL);
    let url = API_URL + "/getJobs";
    axios
      .get(url)
      .then((data) => {
        // console.log("getJobs");
        setDynamicRoutes(data.data);
        let a = removeDuplicates(data.data.map((item) => item.username));
        setPosterRoutes(a);
        setIsLoading(false);
        // console.log(posterRoutes);
      })
      .catch((err) => {
        // console.log(err);
        document.write("Error occured");
      });
  };
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            exact
            path="/jobs"
            element={<Home filter="false" data={dynamicRoutes} />}
          ></Route>
          <Route
            exact
            path="/*"
            element={<Home filter="false" data={dynamicRoutes} />}
          ></Route>
          <Route
            exact
            path="/"
            element={<LandingPage filter="false" data={dynamicRoutes} />}
          ></Route>
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPassword />}
          ></Route>
          <Route exact path="/how-it-works" element={<HowItWorks />}></Route>
          <Route exact path="/modal" element={<Modal />}></Route>
          {localStorage.getItem("token") ? (
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  setJobs={setDynamicRoutes}
                  userData={userData}
                  jobs={dynamicRoutes}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              }
            ></Route>
          ) : null}
          {dynamicRoutes.map((item) => {
            return (
              <Route path={item.id} element={<Profile data={item} />}></Route>
            );
          })}
          {posterRoutes.map((item) => {
            return (
              <Route
                path={item}
                element={
                  <JobPoster
                    filter="true"
                    username={item}
                    data={dynamicRoutes}
                  />
                }
              ></Route>
            );
          })}
          <Route exact path="/signup" element={<SignUp />}></Route>
          <Route
            exact
            path="/login"
            element={<Login userData={userData} setUserData={setUserData} />}
          ></Route>
        </Routes>
      </Router>
      {/* {console.log(dynamicRoutes)} */}
      {/* <SignUp /> */}
      {/* <Login /> */}
    </div>
  );
}

export default App;
