import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Job from "../job";
import { CREATE_JOB_EMAIL } from "../config";

function MyJobs(props) {
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState("a");

  const toggleModal = () => {
    setModal(!modal);
  };

  // useEffect(() => {
  //   console.log(props.isLoading);
  // }, [props.data]);

  useEffect(() => {
    if (modal) {
      document.body.classList.add("active-modal");
    } else if (!modal) {
      document.body.classList.remove("active-modal");
    }
  }, [modal]);
  // console.log(props.userData);
  const handleDelete = (id) => {
    // console.log(id);
    const updatedData = props.data.filter((item) => item.id !== id);
    props.setJobs(updatedData);
  };
  return (
    <div>
      <br />
      <br />
      <br />

      <div className="row mx-0">
        {props.data.map((item) => {
          // console.log(item.company_name);
          if (item.username === localStorage.getItem("username"))
            return (
              <Job
                id={item.id}
                username={item.username}
                company_name={item.company_name}
                loggedIn="true"
                setData={handleDelete}
              />
            );
        })}
        {props.isLoading && (
          <div className="col-12">
            <h6>Loading...</h6>
          </div>
        )}
        {props.data.length === 0 && !props.isLoading && (
          <div className="col-12">
            <h3>No Jobs Posted</h3>
          </div>
        )}
        <div className="container mb-3 px-0">
          <div class="row mx-0 mb-3">
            <div class="col-5">
              <button
                class="btn px-0"
                type="button"
                onClick={() => {
                  toggleModal();
                  setToggle("a");
                }}
              >
                {/* <i class="fa fa-filter d-md-none"></i> */}
                <small class="">Menu</small>
                <i class="fa fa-caret-down ml-1 ml-md-2"></i>
              </button>
            </div>
            <div class="col-2 text-center">
              <button
                onClick={() => {
                  toggleModal();
                  setToggle("b");
                }}
                class="btn px-0"
              >
                <i class="fa fa-plus-circle fa-2x"></i>
              </button>
            </div>
            {/* <div class="col-5 text-right">
                        <button class="btn px-0" onclick="openActionsPanel()">
                          <i class="fa fa-bars d-md-none"></i>
                          <small class="">Actions</small>
                          <i class="fa fa-caret-down ml-1 ml-md-2"></i>
                        </button>
                      </div> */}
          </div>
        </div>
        {modal && (
          <div className="modal" style={{ display: "flex" }}>
            <div
              onClick={() => {
                toggleModal();
              }}
              className="overlay"
            ></div>
            {toggle === "a" ? (
              <div className="filter-content">
                <h2>Menu</h2>
                <hr />
                <div>
                  <Link to="/jobs">Home</Link>
                  <hr />
                  <Link to="/how-it-works">How It Works</Link>
                </div>
                <button className="close-modal" onClick={toggleModal}>
                  CLOSE
                </button>
              </div>
            ) : (
              <div className="modal-content">
                <h2>Note</h2>
                <div>
                  <p>
                    Post a new job?
                  </p>
                  <br />
                  <a
                    // href={
                    //   "mailto:" +
                    //   props.data.email +
                    //   "?subject=Application for the job"
                    // }
                    href={"mailto:" + CREATE_JOB_EMAIL + "?subject=New Job Posting"}
                    className="btn primary_btn px-1 py-1"
                  >
                    Continue
                  </a>
                </div>
                <button className="close-modal" onClick={toggleModal}>
                  CLOSE
                </button>
              </div>
            )}
          </div>
        )}
        {/* <Job username={props.data[0].username} /> */}
      </div>
    </div>
  );
}

export default MyJobs;
